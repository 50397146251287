import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { share } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  #http = inject(HttpClient);

  get<T>(url: string) {
    return this.#http.get<T>(`${API_URL}${url}`).pipe(share());
  }

  put<T, K>(url: string, body: T) {
    return this.#http.put<K>(`${API_URL}${url}`, body).pipe(share());
  }

  post<T, K>(url: string, body?: T) {
    return this.#http.post<K>(`${API_URL}${url}`, body).pipe(share());
  }

  delete<T>(url: string) {
    return this.#http.delete<T>(`${API_URL}${url}`).pipe(share());
  }
}
